import configMap from "src/shared/constants/configMap";
import _BRANCH from "./constants/_BRANCH";

export const BRANCH = _BRANCH;

export const curEnv = (() => {
	if (BRANCH === "dev") return "dev";
	if (BRANCH === "staging") return "staging";
	if (BRANCH === "dogfooding") return "dogfooding";
	if (BRANCH === "prod") return "prod";
	if (__DEV__) return "dev";
	return "missing";
})();
export const curBackend = (() => {
	if (BRANCH === "dev") return "dev";
	if (BRANCH === "staging") return "staging";
	if (BRANCH === "dogfooding") return "prod";
	if (BRANCH === "prod") return "prod";
	if (__DEV__) return "dev";
	return "missing";
})();

export const isDev = curEnv === "dev";
export const notProd = curEnv === "dev" || curEnv === "staging";
export const isProd = curEnv === "dogfooding" || curEnv === "prod";
const config = configMap[curBackend];
export const authorizationConfig = config.authorizationConfig;
export const AMPLITUDE_KEY = config.amplitudeConfig.key;

/**
 * I'd like to put this in `featureFlags.ts` but it causes a circular dependency
 */
const LOCAL_GRAPHQL_API = false && isDev;
if (LOCAL_GRAPHQL_API) {
	if (isDev) {
		config.graphqlConfig.api_endpoint = "http://localhost:4000/graphql";
	} else {
		console.error("LOCAL_GRAPHQL_API is true but not in dev mode");
	}
}

export default config;
